<template>
  <section class="vh-100 d-flex align-items-center justify-content-center">
    <div class="container">
      <div class="row">
        <div
          class="
            col-12
            text-center
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <div>
            <a @click="backRouter">
              <img
                class="img-fluid w-75"
                src="@assets/images/illustrations/404.png"
                alt="404 not found"
              />
            </a>
            <h1 class="mt-5"
              >Pagina
              <span class="font-weight-bolder text-primary"
                >No Encontrada</span
              ></h1
            >
            <p class="lead my-4"
              >Oops! Parece que la pagina que estas tratando de acceder, no
              existe.</p
            >
            <a @click="backRouter" class="btn btn-primary animate-hover">
              <i class="fa fa-chevron-left mr-2 animate-left-3"></i>
              Regresar
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ErrorCodeLayout',
  components: {},
  methods: {
    backRouter() {
      this.$router.back()
    },
  },
}
</script>

<style></style>
